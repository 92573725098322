import { createGlobalStyle } from "styled-components";
import { sizes } from "../styles/sizes";

const Global = createGlobalStyle`
  @font-face {
    font-family: 'RobotoBold';
    src: url('/fonts/Roboto-Black.woff2') format('woff2');
    font-display: fallback;
  }
  
  :root {
    --color-green-1: hsl(149, 83%, 48%);
    --color-black-1: hsl(0, 0%, 6%);
    --color-white-1: hsl(0, 0%, 97%);
    --color-white-2: hsl(0, 0%, 88%);
    --color-white-3: hsl(0, 0%, 87%);
    --color-blue-1: hsl(236, 100%, 91%);
    --color-blue-0: hsl(193, 100%, 93%);
    --color-blue-2: hsl(185, 100%, 84%);
    --color-blue-3: hsl(176, 98%, 67%);
    --color-blue-4: hsl(176, 55%, 68%);
    --color-blue-5: hsl(176, 75%, 43%);
    --color-blue-6: hsl(185, 100%, 56%);
    --color-blue-7: hsl(214, 100%, 62%);
    --color-blue-8: hsl(219, 100%, 55%);
    --color-blue-9: hsl(228, 100%, 56%);
    --color-blue-10: hsl(240, 25%, 14%);
    --color-blue-11: hsl(240, 6%, 10%);
    --color-blue-12: hsl(240, 8%, 13%);
    --color-blue-13: hsl(240, 14%, 10%);
    --gradient-blue-2: linear-gradient(
      135deg,
      var(--color-blue-9),
      var(--color-blue-6)
    );
    --gradient-blue2-2: linear-gradient(
      135deg,
      var(--color-blue-5),
      var(--color-blue-9)
    );
    --gradient-blue3-2: linear-gradient(
      135deg,
      var(--color-blue-8),
      var(--color-blue-6)
    );
    --gradient-blue-2-alpha: linear-gradient(
      135deg,
      hsl(228, 100%, 56%, 0.4),
      hsl(185, 100%, 56%, 0.4)
    );
    --gradient-blue1-3: linear-gradient(
      to right,
      var(--color-blue-3),
      var(--color-blue-5),
      var(--color-blue-8)
    );
    --gradient-blue2-3: linear-gradient(
      to right,
      var(--color-blue-7),
      var(--color-blue-9),
      var(--color-blue-7)
    );
    --gradient-blue3-3: linear-gradient(
      to right,
      var(--color-blue-2),
      var(--color-white-1),
      var(--color-blue-2)
    );
    --gradient-blue-ghost: linear-gradient(
      to right, 
      hsla(185, 100%, 84%, 0.05), 
      hsla(0, 0%, 97%, 0.1), 
      hsla(185, 100%, 84%, 0.05) 
    );
    --gradient-button-hover-blue-1: 0 4px 2px 0px rgba(0, 0, 0, 0.4), 0 0 0 2px var(--color-blue-10),
      0 0 0 5px var(--color-blue-3);
    --gradient-button-hover-blue-2: 0 0 0 2px var(--color-white-1),
      0 0 0 5px var(--color-blue-3);
    --gradient-button-back-blue: linear-gradient(
      135deg,
      var(--color-blue-0)
    );
    --color-purple-1: hsl(240, 100%, 97%);
    --color-purple-2: hsl(259, 100%, 93%);
    --color-purple-3: hsl(260, 100%, 87%);
    --color-purple-4: hsl(260, 100%, 79%);
    --color-purple-5: hsl(275, 100%, 65%);
    --color-purple-6: hsl(236, 100%, 91%);
    --color-purple-7: hsl(237, 100%, 75%);
    --color-purple-8: hsl(261, 90%, 65%);
    --color-purple-9: hsl(255, 87%, 56%);
    --color-purple-10: hsl(262, 100%, 45%);
    --color-purple-11: hsl(259, 69%, 41%);
    --color-purple-12: hsl(237, 69%, 57%);
    --color-purple-13: hsl(240, 48%, 72%);
    --color-purple-14: hsl(240, 28%, 50%);
    --color-purple-15: hsl(245, 15%, 35%);
    --color-purple-16: hsl(240, 26%, 22%);
    --color-purple-17: hsl(243, 25%, 16%);
    --gradient-purple1-2: linear-gradient(
      135deg,
      var(--color-purple-5),
      var(--color-purple-10)
    );
    --gradient-purple2-2: linear-gradient(
      135deg,
      var(--color-white-1),
      var(--color-purple-3)
    );
    --gradient-purple3-2: linear-gradient(
      135deg,
      var(--color-purple-7),
      var(--color-purple-12)
    );
    --gradient-purple1-2-alpha: linear-gradient(
      135deg,
      hsl(275, 100%, 65%, 0.4),
      hsl(262, 100%, 45%, 0.4)
    );
    --gradient-purple2-2-alpha: radial-gradient(
      circle at -5%,
      hsl(259, 100%, 93%, 0.1) 50%,
      hsl(240, 28%, 50%, 0.4)
    );
    --gradient-purple1-3: linear-gradient(
      135deg,
      var(--color-purple-4),
      var(--color-purple-5),
      var(--color-purple-10)
    );
    --gradient-purple2-3: linear-gradient(
      135deg,
      var(--color-purple-8),
      var(--color-purple-5),
      var(--color-purple-8)
    );
    --gradient-purple3-3: linear-gradient(
      135deg,
      var(--color-purple-4),
      var(--color-purple-5),
      var(--color-purple-4)
    );
    --gradient-button-hover-purple-1: 0 4px 2px 0px rgba(0, 0, 0, 0.4), 0 0 0 2px var(--color-blue-10),
      0 0 0 5px var(--color-purple-5);
    --gradient-button-hover-purple-2: 0 0 0 2px var(--color-white-1),
      0 0 0 5px var(--color-purple-5);
    --gradient-button-back-purple: linear-gradient(
      135deg,
      var(--color-purple-10),
      var(--color-pink-0)
    );
    --color-pink-0: hsl(288, 100%, 85%);
    --color-pink-1: hsl(305, 100%, 67%);
    --color-pink-2: hsl(312, 100%, 50%);
    --color-pink-3: hsl(324, 100%, 57%);
    --color-pink-4: hsl(325, 100%, 50%);
    --color-pink-5: hsl(312, 74%, 47%);
    --gradient-pink-2: linear-gradient(
      135deg,
      var(--color-pink-1),
      var(--color-pink-4)
    );
    --gradient-pink2-2: linear-gradient(
      135deg,
      var(--color-pink-5),
      var(--color-purple-5)
    );
    --gradient-pink-3: linear-gradient(
      to right,
      var(--color-pink-2),
      var(--color-pink-1),
      var(--color-pink-2)
    );
    --gradient-button-hover-pink-1: 0 4px 2px 0px rgba(0, 0, 0, 0.4), 0 0 0 2px var(--color-blue-10),
      0 0 0 5px var(--color-pink-3);
    --gradient-attention-1: linear-gradient(
      135deg,
      var(--color-purple-8),
      var(--color-blue-7),
      var(--color-blue-4),
      var(--color-blue-6)
    );
    --gradient-attention-2: linear-gradient(
      135deg,
      var(--color-purple-8),
      var(--color-blue-7),
      var(--color-purple-5),
      var(--color-purple-12)
    );
    --gradient-attention-3: linear-gradient(
      135deg,
      var(--color-pink-2),
      var(--color-purple-4),
      var(--color-purple-7),
      var(--color-purple-10)
    );
    --gradient-whimsical: linear-gradient(
      135deg,
      var(--color-blue-6),
      var(--color-purple-8),
      var(--color-purple-9),
      var(--color-blue-6)
    );
    --calendar-active-day: 0 4px 2px 0px rgba(0, 0, 0, 0.4), 0 0 0 4px var(--color-blue-3);
    --card-border-fitness: 0 0 0 6px hsla(240, 100%, 0%, 0.3);
    --card-border-nutrition: 0 0 0 6px hsl(237, 100%, 74%, 0.4);
    --gradient-green-2: linear-gradient(
      135deg,
      hsl(149, 80%, 36%),
      var(--color-green-1)
    );
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    background: var(--color-blue-10);
    width: 100%;
    height: 100%;
    scrollbar-color: #5AFDF2 none;
  }

  section {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: unset;
    line-height: 1;
  }

  p {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.7rem;
    color: var(--paragraph, var(--color-blue-1));
    line-height: 1.8;
    ${sizes.aboveTablet} {
      font-size: 2rem;
    }
  }

  li, ol {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.7rem;
    ${sizes.aboveTablet} {
      font-size: 2rem;
    }
  }

  strong {
    color: var(--strong);
  }

  a, button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  a {
    font-size: 1.7rem;
    color: var(--a-tag);
    ${sizes.aboveTablet} {
      font-size: 2rem;
    }
  }

  button {
    margin: 0;
    padding: 0;
  }

  label {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  input, textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    &::placeholder {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
  }

`;

export default Global;
