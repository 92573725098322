import Link from "next/link";
import styled from "styled-components";

import { ProgramDescription } from "../../../styles/typography";

const ContactLink = styled.a`
  font-size: inherit;
  color: var(--color-blue-3);
  font-weight: 700;
  transition: color 300ms ease-in-out;
  &:hover {
    color: var(--color-purple-4);
  }
`;

const ContactUsLink: React.FC = ({ children }) => (
  <Link href="/dashboard/user/contact" passHref={true}>
    <ContactLink>{children}</ContactLink>
  </Link>
);

export const GenericNetworkErrorMessage = () => {
  return (
    <ProgramDescription>
      Sorry there was either a server error or your network connection was
      interrupted. Please click the button below to refresh the page and try
      again. <ContactUsLink>Contact us if you keep having issues</ContactUsLink>
      .
    </ProgramDescription>
  );
};
