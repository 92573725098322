import Image from "next/image";
import styled from "styled-components";

import { FWWLogo } from "../svgs/FWWLogo";
import { sizes } from "../../styles/sizes";

interface UserAvatarChipProps {
  width?: number;
  avatar?: string;
}

const AvatarContainer = styled.div`
  background: var(--color-pink-4);
  border-radius: 50%;
  width: var(--width);
  height: var(--height);
  box-shadow: var(--avatar-box-shadow);
  overflow: hidden;
`;

const BaseAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-purple-17);
  border-radius: 50%;
  width: var(--width);
  height: var(--width);
  box-shadow: var(--avatar-box-shadow);
  overflow: hidden;
`;

const Logo = styled(FWWLogo)`
  width: 26px;
  ${sizes.aboveTablet} {
    width: 36px;
  }
`;

export const UserAvatarChip: React.FC<UserAvatarChipProps> = ({
  width,
  avatar,
}) => {
  const styles = {
    "--width": width ? `${width}px` : "100px",
    "--height": width ? `${width}px` : "100px",
    "--avatar-box-shadow": width
      ? width < 80
        ? "0 0 0 3px var(--color-pink-4)"
        : "0 0 0 4px var(--color-pink-4)"
      : "0 0 0 4px var(--color-pink-4)",
  } as React.CSSProperties;

  return (
    <AvatarContainer style={styles}>
      {avatar && avatar !== "" ? (
        <Image
          src={avatar}
          width={150}
          height={150}
          layout="intrinsic"
          alt="Avatar image"
          title="FWW Challenge Member"
        />
      ) : (
        <BaseAvatarContainer>
          <Logo gradientId="fwwBaseAvaterLogo" />
        </BaseAvatarContainer>
      )}
    </AvatarContainer>
  );
};
