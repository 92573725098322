import { BaseSVGProps } from "../../types";

export const RefreshIcon: React.FC<BaseSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      viewBox="0 0 177 179"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path
        id="refresh-icon-container"
        fill="none"
        d="M0 0h176.622v178.192H0z"
      />
      <g id="switch-logo" fillRule="nonzero">
        <path
          style={{ fill: "var(--bottom-arrow-fill, var(--color-white-1))" }}
          id="bottom-arrow"
          d="M168.724 89.003a6 6 0 015.987 6.393c-1.449 20.111-9.775 39.806-24.992 55.059-33.421 33.345-88.174 33.599-121.916.762l-9.598 9.599-14.348-53.563 53.557 14.354-9.572 9.571a58.453 58.453 0 0040.132 15.963c30.11 0 55.209-23.273 58.143-52.661a5.959 5.959 0 015.936-5.435c4.81-.042 11.71-.042 16.671-.042z"
        />
        <path
          style={{ fill: "var(--top-arrow-fill, var(--color-white-1))" }}
          id="top-arrow"
          d="M7.817 89.003a6.001 6.001 0 01-5.988-6.387C5.223 38.007 42.961 2.35 88.374 2.35a86.794 86.794 0 0160.572 24.646l9.59-9.591 14.348 53.557-53.557-14.348 9.575-9.574a58.45 58.45 0 00-40.129-15.96c-30.041 0-55.095 23.168-58.122 52.466a5.96 5.96 0 01-5.934 5.416c-4.863.041-11.878.041-16.9.041z"
        />
      </g>
    </svg>
  );
};
