import { BaseSVGProps } from "../../types"

export const WorkoutTrackingResultsIcon: React.FC<BaseSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      viewBox="0 0 198 172"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path
        id="tracking-results-icon-container"
        fill="none"
        d="M0 0h197.942v171.763H0z"
      />
      <path
        style={{ fill: "var(--results-card-fill, var(--color-white-1))" }}
        id="data-card-1"
        d="M196.149 14.291a12.003 12.003 0 00-12.002-12.003H13.795A12.003 12.003 0 001.793 14.291v20.368a11.999 11.999 0 0012.002 12.003h170.352a12.003 12.003 0 0012.002-12.003V14.291zM21.54 15.38c5.02 0 9.095 4.076 9.095 9.095 0 5.019-4.075 9.095-9.095 9.095-5.019 0-9.094-4.076-9.094-9.095 0-5.019 4.075-9.095 9.094-9.095z"
      />
      <path
        style={{ fill: "var(--results-card-fill, var(--color-white-1))" }}
        id="data-card-2"
        d="M196.149 75.697a12.002 12.002 0 00-12.002-12.003H13.795A12.003 12.003 0 001.793 75.697v20.369a12.002 12.002 0 0012.002 12.002h170.352a12.002 12.002 0 0012.002-12.002V75.697zM21.54 76.787c5.02 0 9.095 4.075 9.095 9.094 0 5.02-4.075 9.095-9.095 9.095-5.019 0-9.094-4.075-9.094-9.095 0-5.019 4.075-9.094 9.094-9.094z"
      />
      <path
        style={{ fill: "var(--results-card-fill, var(--color-white-1))" }}
        id="data-card-3"
        d="M196.149 137.158a12.002 12.002 0 00-12.002-12.003H13.795a12.003 12.003 0 00-12.002 12.003v20.369a12.002 12.002 0 0012.002 12.002h170.352a12.002 12.002 0 0012.002-12.002v-20.369zm-174.609 1.09c5.02 0 9.095 4.075 9.095 9.094 0 5.02-4.075 9.095-9.095 9.095-5.019 0-9.094-4.075-9.094-9.095 0-5.019 4.075-9.094 9.094-9.094z"
      />
    </svg>
  )
}
