import styled from "styled-components"

import { NavigationArrowDirection } from "../../types"

interface NavigationArrowProps {
  width: number
  direction: NavigationArrowDirection
}

const ArrowContainer = styled.div`
  position: relative;
  width: 60%;
  height: 60%;
  transform: var(--direction);
  pointer-events: none;
`

const VerticalArrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: var(--background-top-webkit);
  background: var(--background-top);
  border-radius: 24px;
  height: 100%;
  width: var(--width);
`

const HorizontalArrow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--background-bottom-webkit);
  background: var(--background-bottom);
  border-radius: 24px;
  width: 100%;
  height: var(--width);
`

export const NavigationArrow: React.FC<NavigationArrowProps> = ({
  width,
  direction,
}) => {
  const barWidth = width / 7.8

  const style = {
    "--direction":
      direction === "down"
        ? "translateY(-25%) rotate(45deg)"
        : direction === "right"
        ? "translateX(-25%) rotate(-45deg)"
        : direction === "up"
        ? "rotate(-1350deg)"
        : "rotate(-225deg)",
    "--width": `${barWidth}px`,
    "--background-top":
      "linear-gradient(to bottom, var(--color-blue-3), var(--color-purple-4),var(--color-purple-5) 88%)",
    "--background-top-webkit":
      "-webkit-linear-gradient(to bottom, var(--color-blue-3), var(--color-purple-4),var(--color-purple-5) 88%)",
    "--background-bottom":
      "linear-gradient(to right, var(--color-blue-3), var(--color-purple-4), var(--color-purple-5))",
    "--background-bottom-webkit":
      "-webkit-linear-gradient(top, var(--color-blue-3), var(--color-purple-4), var(--color-purple-5))",
  } as React.CSSProperties

  return (
    <ArrowContainer style={style}>
      <VerticalArrow />
      <HorizontalArrow />
    </ArrowContainer>
  )
}
