import { useEffect, useRef, CSSProperties } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";

import { SolidHeadlineStyle } from "../../../styles/typography";
import { DummyButtonSmallIcon } from "../../buttons/dummyButtons/DummyButtonSmallIcon";
import { FunctionalOverlayTransition } from "../../../animations/transitions/FunctionalOverlayTransition";
import {
  toastOverlayEnterAni,
  toastOverlayExitAni,
} from "../../../animations/overlays";
import { sizes } from "../../../styles/sizes";

interface ErrorToasterProps {
  isOpen: boolean;
  headline: string;
}

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(240, 0%, 0%, 0.3);
  width: 100%;
  z-index: 999;
`;

const ToastContainer = styled.div`
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 40px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  justify-items: start;
  gap: 20px;
  background: var(--toaster-background);
  border-radius: 20px 20px 12px 12px;
  opacity: 0;
  box-shadow: 0 0 0 6px hsla(240, 0%, 0%, 0.6);
  max-width: 400px;
  transform: translateY(100%) scale(0.8);
  ${sizes.aboveMobile} {
    left: unset;
  }
`;

export const ErrorToaster: React.FC<ErrorToasterProps> = ({
  isOpen,
  headline,
  children,
}) => {
  const toasterRef = useRef<HTMLDivElement | null>(null);

  const { reload } = useRouter();

  useEffect(() => {
    const toaster = toasterRef.current;

    if (toaster && isOpen) {
      toastOverlayEnterAni(toaster);
    }

    if (toaster && !isOpen) {
      toastOverlayExitAni(toaster);
    }
  }, [isOpen]);

  const handleRefreshWindow = () => {
    reload();
  };

  const errorStyles = {
    "--toaster-background": "var(--gradient-pink-2)",
    "--solid-headline": "var(--color-white-1)",
    "--card-description": "var(--color-white-1)",
  } as React.CSSProperties;

  return (
    <FunctionalOverlayTransition isOpen={isOpen}>
      <BackgroundOverlay style={errorStyles}>
        <ToastContainer ref={toasterRef}>
          <h4>
            <SolidHeadlineStyle>{headline}</SolidHeadlineStyle>
          </h4>
          {children}
          <DummyButtonSmallIcon
            theme="blue"
            type="refresh"
            handleClick={handleRefreshWindow}
          >
            Refresh Window
          </DummyButtonSmallIcon>
        </ToastContainer>
      </BackgroundOverlay>
    </FunctionalOverlayTransition>
  );
};
