import create, { SetState } from "zustand";

export interface User {
  isMember: boolean;
  username?: string;
  avatar?: string;
}

type UserStore = {
  user: User | null;
  isUserLoggedIn: boolean;
  isLoadingUser: boolean;
  setIsUserLoggedIn: (isLoggedIn: boolean) => void;
  setIsLoadingUser: (isLoading: boolean) => void;
  setUser: (user: User | null) => void;
  updateUsername: (username: string) => void;
};

export const userStore = create<UserStore>((set: SetState<UserStore>) => ({
  user: {
    isMember: false,
    username: "",
    avatar: "",
  },
  isUserLoggedIn: false,
  isLoadingUser: false,
  setIsUserLoggedIn: (isLoggedIn: boolean) =>
    set((state) => {
      return {
        ...state,
        isUserLoggedIn: isLoggedIn,
      };
    }),
  setIsLoadingUser: (isLoading: boolean) =>
    set((state) => ({
      ...state,
      isLoading: isLoading,
    })),
  setUser: (user: User | null) =>
    set((state) => {
      const username = user?.username ? user.username : "";
      const avatar = user?.avatar ? user.avatar : "";

      return {
        ...state,
        user: user
          ? {
              isMember: user.isMember,
              username: username,
              avatar: avatar,
            }
          : null,
      };
    }),
  updateUsername: (username: string) =>
    set((state) => ({
      ...state,
      user: state.user
        ? {
            ...state.user,
            username: username,
          }
        : null,
    })),
}));
