import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";

import { NavProfileAvatar } from "./NavProfileAvatar";
import { BackButtonNavBar } from "../../buttons/BackButtonNavBar";
import { SyncingChip } from "../../chips/SyncingChip";
import { Portal } from "../../shared/Portal";
import { ErrorToaster } from "../../overlays/ErrorToaster";
import { GenericNetworkErrorMessage } from "../../overlays/overlayContentOptions/GenericNetworkErrorMessage";
import { useGetUserData } from "../../../hooks/data/useGetUserData";
import { sizes } from "../../../styles/sizes";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
`;

const NavBarContainer = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  ${sizes.aboveIpadPro} {
    padding: 16px 0 0 0;
  }
`;

const AvatarAndSyncingContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: fit-content;
`;

export const AppNav = () => {
  const [isBackButtonActive, setIsBackButtonActive] = useState<boolean>(true);
  const [hideNavAvatar, setHideNavAvatar] = useState<boolean>(true);

  const { pathname, back, isReady } = useRouter();

  const { data, isError, isSuccess } = useGetUserData(
    "/api/data/get-user-data"
  );

  useEffect(() => {
    if (isReady) {
      const hideAvatar = pathname.split("/")[1] === "";
      setHideNavAvatar(hideAvatar);

      const showBackButton = pathname.split("/").length > 2;
      setIsBackButtonActive(showBackButton);
    }
  }, [pathname, isReady]);

  const handleBackButtonClick = () => {
    back();
  };

  return (
    <>
      <Container tabIndex={1}>
        <NavBarContainer>
          {isBackButtonActive ? (
            <BackButtonNavBar
              isActive={isBackButtonActive}
              handleClick={handleBackButtonClick}
            />
          ) : (
            <div />
          )}
          {hideNavAvatar ? null : (
            <AvatarAndSyncingContainer>
              <SyncingChip />
              <NavProfileAvatar
                avatar={isSuccess && data?.avatar ? data.avatar : ""}
              />
            </AvatarAndSyncingContainer>
          )}
        </NavBarContainer>
      </Container>
      <Portal>
        <ErrorToaster isOpen={isError} headline="😭 Coun't Get Your User Data.">
          <GenericNetworkErrorMessage />
        </ErrorToaster>
      </Portal>
    </>
  );
};
