import { gsap, Power2, Power3, Back } from "gsap";

// Functional Drawer Enter and Exit Animation
export const drawerOpenFromRightAni = (drawer: HTMLElement) => {
  const tl = gsap.timeline();

  tl.to(drawer, {
    duration: 0.4,
    x: 0,
    ease: Power2.easeInOut,
  });
};

export const drawerClosedToRightAni = (drawer: HTMLElement) => {
  const tl = gsap.timeline();

  tl.to(drawer, {
    duration: 0.4,
    x: "108%",
    ease: Power2.easeInOut,
  });
};

// The Overlay Back Button
export const backButtonEnterAni = (button: HTMLButtonElement) => {
  gsap.fromTo(
    button,
    {
      x: 60,
      autoAlpha: 0,
    },
    {
      x: 0,
      autoAlpha: 1,
      ease: Back.easeInOut,
      delay: 1.5,
    }
  );
};

export const backButtonExitAni = (button: HTMLButtonElement) => {
  gsap.to(button, {
    x: 60,
    autoAlpha: 0,
    ease: Power3.easeOut,
  });
};

export const overlayCloseButtonAni = (
  leftCross: HTMLDivElement,
  rightCross: HTMLDivElement
) => {
  const tl = gsap.timeline();

  tl.to(leftCross, {
    duration: 0.3,
    rotate: -45,
    autoAlpha: 1,
    ease: Power3.easeInOut,
    delay: 0.8,
  }).to(
    rightCross,
    {
      duration: 0.3,
      rotate: 45,
      autoAlpha: 1,
      ease: Power3.easeInOut,
    },
    "-=0.2"
  );
};

export const toastOverlayEnterAni = (toast: HTMLDivElement) => {
  gsap.to(toast, {
    duration: 0.7,
    y: 0,
    scale: 1,
    autoAlpha: 1,
    ease: Back.easeInOut,
  });
};

export const toastOverlayExitAni = (toast: HTMLDivElement) => {
  gsap.to(toast, {
    duration: 0.7,
    y: "100%",
    scale: 0.8,
    autoAlpha: 0,
    ease: Power3.easeInOut,
  });
};

export const decisionBackgroundLayerEnter = (background: HTMLDivElement) => {
  gsap.to(background, {
    duration: 0.2,
    autoAlpha: 1,
  });
};

export const decisionBackgroundLayerExit = (background: HTMLDivElement) => {
  gsap.to(background, {
    duration: 0.2,
    autoAlpha: 0,
  });
};

export const decisionOverlayEnter = (overlay: HTMLDivElement) => {
  gsap.to(overlay, {
    duration: 0.4,
    y: 0,
    autoAlpha: 1,
    scale: 1,
    ease: Back.easeInOut,
    delay: 0.3,
  });
};

export const decisionOverlayExit = (overlay: HTMLDivElement) => {
  gsap.to(overlay, {
    duration: 0.4,
    y: 40,
    autoAlpha: 0,
    scale: 0.9,
    ease: Power3.easeInOut,
  });
};
