import { useEffect, useRef } from "react"
import styled from "styled-components"

import { NavigationArrow } from "../cssDrawings/NavigationArrow"
import {
  backButtonEnterAni,
  backButtonExitAni,
} from "../../animations/navigation"

interface BackButtonProps {
  isActive: boolean
  handleClick: () => void
}

const Button = styled.button`
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:focus {
    outline: 2px solid var(--color-blue-3);
    outline-offset: 4px;
  }
`

export const BackButtonNavBar: React.FC<BackButtonProps> = ({
  isActive,
  handleClick,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const button = buttonRef.current

    if (button && isActive) {
      backButtonEnterAni(button)
    }

    if (button && !isActive) {
      backButtonExitAni(button)
    }
  }, [isActive])

  return (
    <Button
      type="button"
      aria-label="Back button"
      ref={buttonRef}
      onClick={handleClick}
    >
      <NavigationArrow direction="left" width={42} />
    </Button>
  )
}
