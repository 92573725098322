import { useQuery } from "react-query";

import { clientMagic } from "../../../lib/magic";
import { userStore } from "../../../lib/stores/userStore";
import { trainingPlanStore } from "../../../lib/stores/trainingPlanStore";
import { ApiEndpoints, ProgramId } from "../../types";
import { GetUserDataRes } from "../../types/api";

const fetchUserData = async (endpoint: ApiEndpoints, token: string) => {
  const res = await fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new Error(
      "Could not get your user data. Please refresh the page and try again."
    );
  }

  return res.json();
};

export const useGetUserData = (endpoint: ApiEndpoints) => {
  const { setUser, isUserLoggedIn } = userStore((state) => ({
    setUser: state.setUser,
    isUserLoggedIn: state.isUserLoggedIn,
  }));

  const setActiveProgramId = trainingPlanStore(
    (state) => state.setActiveProgramId
  );

  return useQuery<GetUserDataRes>(
    ["getUserData", endpoint],
    async () => {
      const token = await clientMagic?.user.getIdToken();

      return fetchUserData(endpoint, token ?? "");
    },
    {
      enabled: isUserLoggedIn,
      staleTime: 1000 * 60 * 60,
      onSuccess: (data) => {
        const { username, avatar, isMember, activeProgram } = data;
        setUser({
          username: username ?? "",
          avatar: avatar ?? "",
          isMember: isMember,
        });
        setActiveProgramId(activeProgram as ProgramId);
      },
    }
  );
};
