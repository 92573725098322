import { useEffect, useRef } from "react";

import { BluePurpleColor } from "./BluePurpleColor";
import { WhiteColor } from "./WhiteColor";
import { GradientSVGProps, KettlebellLoader } from "../../../types";
import { loadingKettlebellAni } from "../../../animations/svgs";

interface LoadingKettlebellProps extends GradientSVGProps {
  loaderType: KettlebellLoader;
}

export const LoadingKettlebell: React.FC<LoadingKettlebellProps> = ({
  width,
  height,
  className,
  gradientId,
  loaderType,
}) => {
  const kettlebellRef = useRef<SVGPathElement | null>(null);
  const handleRef = useRef<SVGPathElement | null>(null);

  useEffect(() => {
    const kettlebell = kettlebellRef.current;
    const handle = handleRef.current;

    if (kettlebell && handle) {
      loadingKettlebellAni([kettlebell, handle], false);
    }

    return () => {
      if (kettlebell && handle) {
        loadingKettlebellAni([kettlebell, handle], true);
      }
    };
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 142.39 193.6"
    >
      <defs>
        <linearGradient
          id={`${gradientId}1`}
          x1="32.21"
          y1="57.38"
          x2="175.44"
          y2="140.07"
          gradientUnits="userSpaceOnUse"
        >
          {loaderType === "video" ? <BluePurpleColor /> : <WhiteColor />}
          <BluePurpleColor />
        </linearGradient>
        <linearGradient
          id={`${gradientId}2`}
          x1="71.29"
          y1="33.94"
          x2="135.24"
          y2="70.86"
          gradientUnits="userSpaceOnUse"
        >
          {loaderType === "video" ? <BluePurpleColor /> : <WhiteColor />}
        </linearGradient>
      </defs>
      <g
        id="kettlebell-loader"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          style={{
            stroke:
              loaderType === "start_training_plan"
                ? "hsla(240, 100%, 100%, 0.1)"
                : "var(--loader-track-color, hsl(240, 25%, 10%))",
          }}
          id="track-kettlebell"
          d="M70.87 7.02c-16.56.29-36.41 4.47-47.82 17.64-19 21.89 1.36 53.79 1.36 53.79h0a64.19 64.19 0 1092.81-.75h0c2.91-5.83 8-13.63 9.38-26.77 1.92-18.28-15.15-35.08-30.63-40.85-4.61-1.72-14-3.21-24.81-3"
          strokeWidth="16"
        />
        <path
          ref={kettlebellRef}
          id="kettlebell-path"
          d="M103.65 10.05c-16.56.29-36.41 4.47-47.82 17.64-19 21.89 1.36 53.79 1.36 53.79h0a64.19 64.19 0 1092.81-.75h0c2.91-5.83 8-13.63 9.38-26.77 1.92-18.28-15.15-35.08-30.63-40.85-4.61-1.72-14-3.21-24.81-3"
          transform="translate(-32.78 -3.03)"
          strokeWidth="10"
          stroke={`url(#${gradientId}1)`}
        />
      </g>
      <g
        id="handle-hole"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          style={{
            stroke:
              loaderType === "start_training_plan"
                ? "hsla(240, 100%, 100%, 0.1)"
                : "var(--loader-track-color, hsl(240, 25%, 10%))",
          }}
          id="track"
          d="M43.54 34.81c15.05-12.84 47.35-8.93 56.61 2.75 5.82 7.33 5 18.77-1.38 25.73h0a64.24 64.24 0 00-55.89.56c-1.61-1.88-13.44-17 .66-29.04z"
          strokeWidth="16"
        />
        <path
          ref={handleRef}
          id="handle-path"
          d="M76.32 37.84c15.05-12.84 47.35-8.93 56.61 2.75 5.82 7.33 5 18.77-1.38 25.73h0a64.24 64.24 0 00-55.89.56c-1.61-1.88-13.44-17 .66-29.04z"
          transform="translate(-32.78 -3.03)"
          strokeWidth="10"
          stroke={`url(#${gradientId}2)`}
        />
      </g>
    </svg>
  );
};
