import styled, { css } from "styled-components";

import { sizes } from "./sizes";

// Headline Styling

// Small headline for headlines
export const SolidHeadlineStyle = styled.span`
  font-size: 2.3rem;
  color: var(--solid-headline, var(--color-blue-1));
`;

export const SolidMiniSiteHeadlineStyle = styled.span`
  font-size: 4.4rem;
  color: var(--solid-headline, var(--color-blue-1));
  ${sizes.aboveTablet} {
    font-size: 5.6rem;
  }
  ${sizes.aboveIpadPro} {
    font-size: 6rem;
  }
`;

// Bigger gradient headline
export const GradientHeadlineStyle = styled.span`
  padding-bottom: 5px;
  font-size: 4.4rem;
  background: var(--gradient-headline, var(--gradient-attention-1));
  background-clip: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${sizes.aboveIphone11Pro} {
    font-size: 4.8rem;
  }
  ${sizes.aboveMobile} {
    font-size: 6.8rem;
  }
  ${sizes.aboveTablet} {
    font-size: 8rem;
  }
`;

// Gradient headline for list based sections
// Inside a heading 4
export const GradientListHeadlineStyle = styled.span`
  font-size: 2.3rem;
  background: var(--gradient-list-headline, var(--color-blue-1));
  background-clip: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`;

// Small lead in headline for small text
export const PreHeadline = styled.span`
  font-size: 2rem;
  color: var(--pre-headline, var(--color-blue-1));
  text-transform: uppercase;
  letter-spacing: 3px;
  ${sizes.aboveMobile} {
    font-size: 3rem;
  }
`;

export const Headline3 = styled.h3`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 8px;
  width: 100%;
`;

// Navigation

// Label for the navigation cards footer and nav menu
export const PrimaryLabel = styled.h4`
  font-size: 2rem;
  color: var(--card-nav-color);
  text-transform: uppercase;
  transition: color 400ms ease-in-out;
  ${sizes.aboveTablet} {
    font-size: 2.4rem;
  }
`;

// Labels for the secondary nav links
export const TextNavLabel = styled.h4`
  font-size: 1.8rem;
  color: var(--color);
  text-transform: uppercase;
  line-height: 1;
`;

// Description text for the primary program navigation
export const PrimaryNavItemDescription = styled.p`
  font-size: 1.4rem;
  color: var(--color-blue-1);
  text-align: left;
`;

// Labels

// This is the label used in the calendar tag.
// It can be used throughout the site
export const TagLabel = styled.p`
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-tag-label, var(--color-blue-1));
  text-transform: uppercase;
  letter-spacing: 1.4;
`;

// Cards

// Program Card

// Card description
export const ProgramDescription = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--card-description, var(--color-blue-1));
  line-height: 1.6;
  text-align: left;
`;

// Footer labels for the cards
export const FooterStatLabel = styled.p`
  font-size: 1.2rem;
  color: var(--card-footer-label, var(--color-purple-15));
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
`;

// Normal paragraph for addresses
// Anytime yo uneed to show the user their own data.
// Address... journals... etc...
export const NormalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--paragraph-color, var(--color-blue-1));
  line-height: var(--paragraph-line-height, 1);
  ${sizes.aboveMobile} {
    font-size: 1.6rem;
  }
`;

export const BoldLabel = styled.p`
  font-weight: 700;
  font-size: 1.6rem;
  color: var(--label-color, var(--color-blue-1));
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
  transition: color 300ms ease-in-out;
`;

export const WorkoutViewLabel = styled.p`
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--color-blue-1);
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.2rem;
`;

// Typography for workout details and recipe details

const sharedListAttributes = css`
  color: var(--list-color, var(--color-white-1));
  text-transform: capitalize;
  line-height: 1;
`;

export const ListHeadline = styled.h4`
  font-size: 3.6rem;
  ${sharedListAttributes}
`;

export const SubListHeadline = styled.h5`
  font-size: 2.4rem;
  ${sharedListAttributes}
`;

export const ListItem = styled.h6`
  font-size: 1.8rem;
  ${sharedListAttributes}
`;

// Special - Labels And Micelaneous

export const ProgramStatusLabel = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--color-white-1);
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: 1;
`;
