import { Transition } from "react-transition-group"
import { gsap, Power3 } from "gsap"

interface SyncingChipTransitionProps {
  isSyncing: boolean
}

export const SyncingChipTransform: React.FC<SyncingChipTransitionProps> = ({
  isSyncing,
  children,
}) => {
  const chipEnter = (node: HTMLElement): void => {
    if (isSyncing) {
      gsap.fromTo(
        node,
        {
          y: "-100%",
          autoAlpha: 0,
        },
        {
          duration: 0.3,
          y: 0,
          autoAlpha: 1,
          ease: Power3.easeIn,
        }
      )
    }
  }

  const chipExit = (node: HTMLElement): void => {
    if (!isSyncing) {
      gsap.to(node, {
        duration: 0.3,
        y: "-100%",
        autoAlpha: 0,
        ease: Power3.easeOut,
      })
    }
  }

  return (
    <Transition
      in={isSyncing}
      timeout={800}
      mountOnEnter={true}
      unmountOnExit={true}
      onEnter={(node: HTMLElement) => chipEnter(node)}
      onExit={(node: HTMLElement) => chipExit(node)}
    >
      {children}
    </Transition>
  )
}
