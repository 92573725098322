import styled from "styled-components"

import { RenderIcon } from "./RenderIcon"
import {
  blueTheme,
  purpleTheme,
  pinkTheme,
} from "../../../styles/themes/buttonThemes"
import { ColorTheme, ActionButton } from "../../../types"

interface BaseInternalButtonProps {
  theme: ColorTheme
  type: ActionButton
  handleClick: () => void
}

const Button = styled.button`
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-size: 2rem;
  font-weight: 700;
  color: var(--button-label);
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  background: var(--button-background);
  border: none;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.4);
  transition: box-shadow 300ms ease-in-out;
  te &:focus {
    box-shadow: var(--button-hover);
  }
  &:hover {
    box-shadow: var(--button-hover);
  }
`

export const DummyButtonSmallIcon: React.FC<BaseInternalButtonProps> = ({
  theme,
  type,
  handleClick,
  children,
}) => {
  const activeTheme =
    theme === "blue" ? blueTheme : theme === "purple" ? purpleTheme : pinkTheme

  return (
    <Button type="button" style={activeTheme} onClick={handleClick}>
      <RenderIcon type={type} />
      {children}
    </Button>
  )
}
