import { CSSProperties } from "react";

export const blueTheme = {
  "--button-background": "var(--gradient-blue-2)",
  "--button-label": "var(--color-white-1)",
  "--button-hover": "var(--gradient-button-hover-purple-1)",
  "--back-button": "var(--gradient-button-back-blue)",
} as CSSProperties;

export const purpleTheme = {
  "--button-background": "var(--gradient-purple1-2)",
  "--button-label": "var(--color-white-1)",
  "--button-hover": "var(--gradient-button-hover-blue-1)",
  "--back-button": "var(--gradient-button-back-purple)",
} as CSSProperties;

export const pinkTheme = {
  "--button-background": "var(--gradient-pink-2)",
  "--button-label": "var(--color-white-1)",
  "--button-hover": "var(--gradient-button-hover-blue-1)",
  "--back-button": "var(--gradient-button-back-purple)",
} as CSSProperties;

export const trackingButtonTheme = {
  "--button-background": "var(--gradient-blue-2)",
  "--button-hover": "var(--gradient-button-hover-purple-1)",
  "--button-height": "95px",
  "--button-font-size": "2.2rem",
} as CSSProperties;

export const assessmentWorkoutButtonTheme = {
  "--button-background":
    "linear-gradient(135deg, var(--color-blue-9), var(--color-purple-9))",
  "--button-hover": "var(--gradient-button-hover-purple-1)",
  "--button-height": "75px",
  "--button-font-size": "2rem",
} as CSSProperties;

export const workoutLinkButtonTheme = {
  "--button-background": "var(--color-white-1)",
  "--button-hover": "var(--gradient-button-hover-blue-1)",
  "--button-height": "75px",
  "--button-font-size": "1.6rem",
  "--button-font-color": "var(--color-purple-16)",
} as CSSProperties;

export const journalButtonTheme = {
  "--button-background": "var(--gradient-purple1-2)",
  "--button-hover": "var(--gradient-button-hover-blue-1)",
  "--button-height": "95px",
  "--button-font-size": "2.2rem",
} as CSSProperties;

export const trainingPlanButtonTheme = {
  "--button-background":
    "linear-gradient(135deg, var(--color-blue-6), var(--color-blue-9))",
  "--button-hover": "var(--gradient-button-hover-purple-1)",
  "--button-height": "95px",
  "--button-font-size": "2.2rem",
} as CSSProperties;

export const primaryGoalTheme = {
  "--button-background": "var(--gradient-blue2-2)",
  "--button-hover": "var(--gradient-button-hover-purple-1)",
} as CSSProperties;

export const busyGoalTheme = {
  "--button-background": "var(--gradient-purple3-2)",
  "--button-hover": "var(--gradient-button-hover-blue-1)",
} as CSSProperties;

export const fitChallengeTheme = {
  "--button-background": "var(--gradient-blue3-2)",
  "--button-hover": "var(--gradient-button-hover-purple-1)",
} as CSSProperties;

export const nutritionChallengeTheme = {
  "--button-background": "var(--gradient-purple1-2)",
  "--button-hover": "var(--gradient-button-hover-blue-1)",
} as CSSProperties;

export const coachingClassTheme = {
  "--button-background": "var(--gradient-pink2-2)",
  "--button-hover": "var(--gradient-button-hover-blue-1)",
} as CSSProperties;

export const mondayMotivationTheme = {
  "--button-background": "var(--gradient-whimsical)",
  "--button-hover": "var(--gradient-button-hover-purple-1)",
} as CSSProperties;

export const disabledActionLinkTheme = {
  "--button-background": "var(--color-purple-16)",
  "--button-hover": "none",
  "--button-height": "var(--button-height)",
  "--button-font-size": "2rem",
} as CSSProperties;
