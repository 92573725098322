import { BaseSVGProps } from "../../types"

export const TrackingIcon: React.FC<BaseSVGProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      viewBox="0 0 190 140"
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
    >
      <path
        id="workout-tracking-icon"
        fill="none"
        d="M0 0h189.555v139.352H0z"
      />
      <path
        id="graph-line1"
        d="M28.574 87.686l32.704-45.831"
        fill="none"
        stroke="#f8f8f8"
        strokeWidth="14.58"
      />
      <path
        id="graph-line2"
        d="M77.151 41.855l36.619 66.544"
        fill="none"
        stroke="#f8f8f8"
        strokeWidth="14.58"
      />
      <path
        id="graph-line3"
        d="M126.364 107.172l37.779-75.331"
        fill="none"
        stroke="#f8f8f8"
        strokeWidth="14.58"
      />
      <path
        id="ring-data-point1"
        d="M19.739 82.519c9.531 0 17.269 7.738 17.269 17.269 0 9.532-7.738 17.27-17.269 17.27-9.531 0-17.269-7.738-17.269-17.27 0-9.531 7.738-17.269 17.269-17.269zm0 5.425c6.537 0 11.845 5.307 11.845 11.844 0 6.538-5.308 11.845-11.845 11.845-6.537 0-11.844-5.307-11.844-11.845 0-6.537 5.307-11.844 11.844-11.844z"
        fill="#f8f8f8"
        stroke="#f8f8f8"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        id="ring-data-point2"
        d="M69.888 12.519c9.531 0 17.269 7.738 17.269 17.269 0 9.532-7.738 17.27-17.269 17.27-9.531 0-17.269-7.738-17.269-17.27 0-9.531 7.738-17.269 17.269-17.269zm0 5.425c6.537 0 11.845 5.307 11.845 11.844 0 6.538-5.308 11.845-11.845 11.845-6.537 0-11.844-5.307-11.844-11.845 0-6.537 5.307-11.844 11.844-11.844z"
        fill="#f8f8f8"
        stroke="#f8f8f8"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        id="ring-data-point3"
        d="M120.037 102.519c9.531 0 17.269 7.738 17.269 17.269 0 9.532-7.738 17.27-17.269 17.27-9.531 0-17.269-7.738-17.269-17.27 0-9.531 7.738-17.269 17.269-17.269zm0 5.425c6.538 0 11.845 5.307 11.845 11.844 0 6.538-5.307 11.845-11.845 11.845-6.537 0-11.844-5.307-11.844-11.845 0-6.537 5.307-11.844 11.844-11.844z"
        fill="#f8f8f8"
        stroke="#f8f8f8"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        id="ring-data-point4"
        d="M170.187 2.519c9.531 0 17.269 7.738 17.269 17.269 0 9.532-7.738 17.27-17.269 17.27-9.531 0-17.269-7.738-17.269-17.27 0-9.531 7.738-17.269 17.269-17.269zm0 5.425c6.537 0 11.844 5.307 11.844 11.844 0 6.538-5.307 11.845-11.844 11.845-6.538 0-11.845-5.307-11.845-11.845 0-6.537 5.307-11.844 11.845-11.844z"
        fill="#f8f8f8"
        stroke="#f8f8f8"
        strokeWidth="1"
        strokeLinecap="round"
      />
    </svg>
  )
}
