import create, { SetState } from "zustand";

import { ActiveMonth, CalendarDayStatus, ProgramId } from "../../src/types";
import { TrainingPlanDay } from "../../src/types/api";

export type KeyDate = {
  monthIndex: ActiveMonth;
  date: number;
};

export interface MonthOneTrainingPlanDays {
  monthIndex: ActiveMonth;
  normalDaysBeforeTrainingPlan: number[];
  trainingPlanDays: number[];
  normalDaysAfterTrainingPlan: number[];
}

export interface MonthTwoAndThreeTrainingPlanDays {
  monthIndex: ActiveMonth;
  trainingPlanDays: number[];
  normalDaysAfterTrainingPlan: number[];
}

type TrainingPlanState = {
  activeProgramId: ProgramId | "";
  programId: ProgramId;
  dayStatus: CalendarDayStatus;
  blankDay: number;
  isTrainingPlanActive: boolean;
  isChallengePlan: boolean;
  trainingPlanLength: number;
  startDate: KeyDate;
  endDate: KeyDate;
  currentTrainingPlanDay: number;
  selectedTrainingPlanDay: number;
  trainingPlanDayGoals: TrainingPlanDay;
  isMonthTwoActive: boolean;
  isMonthThreeActive: boolean;
  setActiveProgramId: (id: ProgramId) => void;
  setProgramId: (id: ProgramId) => void;
  setIsChallengePlan: (isChallengePlan: boolean) => void;
  setTrainingPlanLength: (numberOfDays: number) => void;
  setDayStatus: (status: CalendarDayStatus) => void;
  setBlankDay: (day: number) => void;
  setIsTrainingPlanActive: (status: boolean) => void;
  endTrainingPlan: () => void;
  setTrainingPlanDayGoals: (goals: TrainingPlanDay) => void;
  setActiveMonths: (
    isMonthTwoAction: boolean,
    isMonthThreeActive: boolean
  ) => void;
  setStartDate: (date: number, month: ActiveMonth) => void;
  setEndDate: (date: number, month: ActiveMonth) => void;
  setCurrentTrainingPlanDay: (trainingPlanDay: number) => void;
  setSelectedTrainingPlanDay: (trainingPlanDay: number) => void;
  monthOneTrainingPlanDays: MonthOneTrainingPlanDays;
  monthTwoTrainingPlanDays: MonthTwoAndThreeTrainingPlanDays;
  monthThreeTrainingPlanDays: MonthTwoAndThreeTrainingPlanDays;
  setMonthOneTrainingPlanDays: (
    monthIndex: ActiveMonth,
    normalDaysBefore: number[],
    trainingPlanDays: number[],
    normalDaysAfter: number[]
  ) => void;
  setMonthTwoTrainingPlanDays: (
    monthIndex: ActiveMonth,
    trainingPlanDays: number[],
    normalDays: number[]
  ) => void;
  setMonthThreeTrainingPlanDays: (
    monthIndex: ActiveMonth,
    trainingPlanDays: number[],
    normalDays: number[]
  ) => void;
};

export const trainingPlanStore = create<TrainingPlanState>(
  (set: SetState<TrainingPlanState>) => ({
    activeProgramId: "",
    programId: "" as ProgramId,
    dayStatus: "blank",
    blankDay: 0,
    isTrainingPlanActive: false,
    isChallengePlan: false,
    trainingPlanLength: 0,
    startDate: {
      date: 0,
      monthIndex: 0,
    },
    endDate: {
      date: 0,
      monthIndex: 0,
    },
    currentTrainingPlanDay: 0,
    selectedTrainingPlanDay: 0,
    trainingPlanDayGoals: {
      day: "sunday",
      workoutLinks: [],
      dailyGoals: [],
    },
    isMonthTwoActive: false,
    isMonthThreeActive: false,
    monthOneTrainingPlanDays: {
      monthIndex: 0,
      normalDaysBeforeTrainingPlan: [],
      trainingPlanDays: [],
      normalDaysAfterTrainingPlan: [],
    },
    monthTwoTrainingPlanDays: {
      monthIndex: 0,
      trainingPlanDays: [],
      normalDaysAfterTrainingPlan: [],
    },
    monthThreeTrainingPlanDays: {
      monthIndex: 0,
      trainingPlanDays: [],
      normalDaysAfterTrainingPlan: [],
    },
    setActiveProgramId: (programId: ProgramId) =>
      set((state) => {
        return {
          ...state,
          activeProgramId: programId,
        };
      }),
    setProgramId: (programId: ProgramId) =>
      set((state) => ({
        ...state,
        programId: programId,
      })),
    setIsChallengePlan: (isChallengePlan: boolean) =>
      set((state) => {
        return {
          ...state,
          isChallengePlan: isChallengePlan,
        };
      }),
    setTrainingPlanLength: (numberOfDays) =>
      set((state) => {
        return {
          ...state,
          trainingPlanLength: numberOfDays,
        };
      }),
    setDayStatus: (status) =>
      set((state) => {
        return {
          ...state,
          dayStatus: status,
        };
      }),
    setBlankDay: (day) =>
      set((state) => {
        return {
          ...state,
          blankDay: day,
        };
      }),
    setIsTrainingPlanActive: (status) =>
      set((state) => {
        return {
          ...state,
          isTrainingPlanActive: status,
        };
      }),
    endTrainingPlan: () =>
      set((state) => {
        return {
          ...state,
          isMonthThreeActive: false,
        };
      }),
    setTrainingPlanDayGoals: (goals) =>
      set((state) => {
        return {
          ...state,
          trainingPlanDayGoals: {
            ...goals,
          },
        };
      }),
    setActiveMonths: (isMonthTwoActive, isMonthThreeActive) =>
      set((state) => {
        return {
          ...state,
          isMonthTwoActive: isMonthTwoActive,
          isMonthThreeActive: isMonthThreeActive,
        };
      }),
    setStartDate: (date, month) =>
      set((state) => {
        return {
          ...state,
          startDate: {
            monthIndex: month,
            date: date,
          },
        };
      }),
    setEndDate: (date, month) =>
      set((state) => {
        return {
          ...state,
          endDate: {
            monthIndex: month,
            date: date,
          },
        };
      }),
    setCurrentTrainingPlanDay: (trainingPlanDay) =>
      set((state) => {
        return {
          ...state,
          currentTrainingPlanDay: trainingPlanDay,
        };
      }),
    setSelectedTrainingPlanDay: (trainingPlanDay) =>
      set((state) => {
        return {
          ...state,
          selectedTrainingPlanDay: trainingPlanDay,
        };
      }),
    setMonthOneTrainingPlanDays: (
      monthIndex,
      normalDaysBefore,
      trainingPlanDays,
      normalDaysAfter
    ) =>
      set((state) => {
        return {
          ...state,
          monthOneTrainingPlanDays: {
            monthIndex: monthIndex,
            normalDaysBeforeTrainingPlan: normalDaysBefore,
            trainingPlanDays: trainingPlanDays,
            normalDaysAfterTrainingPlan: normalDaysAfter,
          },
        };
      }),
    setMonthTwoTrainingPlanDays: (monthIndex, trainingPlanDays, normalDays) =>
      set((state) => {
        return {
          ...state,
          monthTwoTrainingPlanDays: {
            monthIndex: monthIndex,
            trainingPlanDays: trainingPlanDays,
            normalDaysAfterTrainingPlan: normalDays,
          },
        };
      }),
    setMonthThreeTrainingPlanDays: (monthIndex, trainingPlanDays, normalDays) =>
      set((state) => {
        return {
          ...state,
          monthThreeTrainingPlanDays: {
            monthIndex: monthIndex,
            trainingPlanDays: trainingPlanDays,
            normalDaysAfterTrainingPlan: normalDays,
          },
        };
      }),
  })
);
