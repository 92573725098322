import { TrackingIcon } from "./TrackingIcon"
import { WorkoutResultsIcon } from "./WorkoutResultCardsIcon"
import { RefreshIcon } from "./RefreshIcon"
import { ActionButton } from "../../../../types"

interface RenderIconProps {
  type: ActionButton
}

export const RenderIcon: React.FC<RenderIconProps> = ({ type }) => {
  switch (type) {
    case "assessment_workout_tracking": {
      return <TrackingIcon />
    }
    case "workout_results": {
      return <WorkoutResultsIcon />
    }
    case "refresh": {
      return <RefreshIcon />
    }
    default: {
      throw new Error(
        "In Dummy Buttons. You passed an icon type that you don't have set up. RenderIcon.tsx in dummyButtons."
      )
    }
  }
}
