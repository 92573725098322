import Link from "next/link";
import styled from "styled-components";

import { UserAvatarChip } from "../../chips/UserAvatarChip";
import { useMatchMedia } from "../../../hooks/useMatchMedia";

interface NavProfileAvatarProps {
  avatar?: string;
}

const ATag = styled.a`
  background: var(--color-pink-4);
  border-radius: 50%;
  box-shadow: 0 0 0 2px var(--color-pink-4);
  outline: none;
  overflow: hidden;
  transition: box-shadow 300ms ease-in-out;
  &:focus {
    box-shadow: var(--gradient-button-hover-blue-1);
  }
  &:hover {
    box-shadow: var(--gradient-button-hover-blue-1);
  }
`;

export const NavProfileAvatar: React.FC<NavProfileAvatarProps> = ({
  avatar,
}) => {
  const isAboveTablet = useMatchMedia(900);

  return (
    <Link href="/dashboard/user" passHref={true}>
      <ATag>
        <UserAvatarChip width={isAboveTablet ? 50 : 40} avatar={avatar} />
      </ATag>
    </Link>
  );
};
