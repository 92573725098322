import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";

// Create client-side Magic instance
const createMagic = (key: string) => {
  if (typeof window !== "undefined") {
    return new Magic(key, {
      extensions: [new OAuthExtension()],
    });
  }
  return;
};

export const clientMagic = createMagic(
  process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY!
);
