import { gsap, Linear, Power3 } from "gsap";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";

gsap.registerPlugin(DrawSVGPlugin);

export const loadingKettlebellAni = (
  paths: SVGPathElement[],
  kill: boolean
) => {
  const tl = gsap.timeline({ repeat: -1 });

  if (kill) {
    tl.kill();
  }

  tl.fromTo(
    paths,
    {
      drawSVG: "0% 0%",
    },
    {
      duration: 2,
      drawSVG: "0% 100%",
      ease: Linear.easeNone,
    }
  ).to(paths, {
    duration: 2,
    drawSVG: "100% 100%",
    ease: Linear.easeNone,
  });
};

export const progressCompleteAni = (
  path: SVGPathElement,
  percentComplete: number,
  updatePercentComplete: (p: number) => void,
  kill: boolean
) => {
  const tl = gsap.timeline();
  const maxLength = DrawSVGPlugin.getLength(path);

  if (kill) {
    tl.kill();
  }

  tl.fromTo(
    path,
    {
      drawSVG: "0% 0%",
    },
    {
      duration: 4 * (percentComplete / 100),
      drawSVG: `${percentComplete}%`,
      ease: Linear.easeNone,
      onUpdate: () => {
        const position = DrawSVGPlugin.getPosition(path)[1];
        const percent = Math.round((position / maxLength) * 100);
        updatePercentComplete(percent);
      },
    }
  );
};

// Animation for the checkmark... used throughout the site
export const setCheckmarkAni = (check: SVGPathElement) => {
  gsap.set(check, { drawSVG: "100% 0%", autoAlpha: 0 });
};

export const checkmarkAni = (check: SVGPathElement, kill: boolean) => {
  const tl = gsap.timeline();

  if (kill) {
    tl.kill();
  }

  tl.fromTo(
    check,
    { drawSVG: "100% 0%", autoAlpha: 1 },
    {
      duration: 0.3,
      drawSVG: "100% 100%",
      ease: Power3.easeIn,
      autoAlpha: 1,
      reversed: true,
    }
  );
};

export const unCheckmarkAni = (check: SVGPathElement, kill: boolean) => {
  const tl = gsap.timeline();

  if (kill) {
    tl.kill();
  }

  tl.fromTo(
    check,
    {
      drawSVG: "100% 100%",
    },
    {
      duration: 0.3,
      drawSVG: "0% 100%",
      ease: Power3.easeOut,
      reversed: true,
    }
  );
};

export const alertPulseAni = (icon: SVGSVGElement) => {
  gsap.fromTo(
    icon,
    {
      transformOrigin: "50% 50%",
      scale: 0.8,
    },
    {
      duration: 2,
      scale: 1.1,
      repeat: -1,
      yoyo: true,
    }
  );
};

export const flexingArmAni = (arm: SVGPathElement) => {
  gsap.to(arm, {
    duration: 1,
    transformOrigin: "20% 90%",
    rotate: 12,
    yoyo: true,
    repeat: -1,
    ease: Linear.easeNone,
  });
};

export const programCardPopUpEnterAni = (el: HTMLDivElement) => {
  gsap.to(el, {
    duration: 0.3,
    y: "-120%",
    autoAlpha: 1,
    ease: Power3.easeInOut,
  });
};

export const programCardPopUpExitAni = (el: HTMLDivElement) => {
  gsap.to(el, {
    duration: 0.3,
    y: 0,
    autoAlpha: 0,
    ease: Power3.easeInOut,
  });
};
