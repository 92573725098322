import { gsap, Power2, Power3, Back } from "gsap";

export const loginMenuOpenAni = (
  dotContainer: HTMLDivElement,
  dot1: HTMLDivElement,
  dot2: HTMLDivElement,
  dot3: HTMLDivElement,
  closeContainer: HTMLDivElement,
  leftCross: HTMLDivElement,
  rightCross: HTMLDivElement,
  kill: boolean
) => {
  const tl = gsap.timeline();

  if (kill) {
    tl.kill();
  }

  tl.to([dot1, dot2, dot3], {
    duration: 0.4,
    y: -2,
    autoAlpha: 0,
    stagger: 0.1,
    ease: Power2.easeInOut
  })
    .to(dotContainer, {
      duration: 0.05,
      autoAlpha: 0
    })
    .to(closeContainer, {
      duration: 0.05,
      autoAlpha: 1
    })
    .to(leftCross, {
      duration: 0.4,
      transformOrigin: "50% 50%",
      rotate: 45,
      autoAlpha: 1,
      ease: Power2.easeInOut
    })
    .to(
      rightCross,
      {
        duration: 0.4,
        transformOrigin: "50% 50%",
        rotate: -45,
        autoAlpha: 1,
        ease: Power2.easeInOut
      },
      "-=0.3"
    );
};

export const loginMenuCloseAni = (
  dotContainer: HTMLDivElement,
  dot1: HTMLDivElement,
  dot2: HTMLDivElement,
  dot3: HTMLDivElement,
  closeContainer: HTMLDivElement,
  leftCross: HTMLDivElement,
  rightCross: HTMLDivElement,
  kill: boolean
) => {
  const tl = gsap.timeline();

  if (kill) {
    tl.kill();
  }

  tl.to(rightCross, {
    duration: 0.4,
    transformOrigin: "50% 50%",
    rotate: 270,
    autoAlpha: 0,
    ease: Power2.easeInOut
  })
    .to(
      leftCross,
      {
        duration: 0.4,
        transformOrigin: "50% 50%",
        rotate: -270,
        autoAlpha: 0,
        ease: Power2.easeInOut
      },
      "-=0.3"
    )
    .to(closeContainer, {
      duration: 0.05,
      autoAlpha: 0
    })
    .to(dotContainer, {
      duration: 0.05,
      autoAlpha: 1
    })
    .to([dot3, dot2, dot1], {
      duration: 0.4,
      y: 0,
      autoAlpha: 1,
      stagger: 0.1,
      ease: Power2.easeInOut
    });
};

// Navigation Kettlebell Animations
export const kettlebellNavIconEnterAni = (icon: SVGSVGElement) => {
  gsap.to(icon, {
    duration: 0.2,
    x: 0,
    autoAlpha: 1,
    ease: Power3.easeInOut,
  })
}

export const kettlebellNavIconLeaveAni = (icon: SVGSVGElement) => {
  gsap.to(icon, {
    duration: 0.2,
    x: -12,
    autoAlpha: 0,
    ease: Power3.easeInOut,
  })
}

// The Overlay Back Button
export const backButtonEnterAni = (button: HTMLButtonElement) => {
  gsap.fromTo(button, {
    x: 60,
    autoAlpha: 0,
  }, {
    x: 0,
    autoAlpha: 1,
    ease: Back.easeInOut,
    delay: 1.5,
  })
}

export const backButtonExitAni = (button: HTMLButtonElement) => {
  gsap.to(button, {
    x: 60,
    autoAlpha: 0,
    ease: Power3.easeOut,
  })
}