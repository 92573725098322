import styled from "styled-components";

import Global from "../styles/Global";
import { AppNav } from "../components/navigation/AppNav";

const BaseContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  isolation: isolate;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-width: 1440px; */
  overflow: hidden;
`;

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Global />
      <BaseContainer>
        <AppNav />
        <ContentContainer>{children}</ContentContainer>
      </BaseContainer>
    </>
  );
};

export default Layout;
