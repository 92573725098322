import styled from "styled-components"
import { useIsFetching } from "react-query"

import { BoldLabel } from "../../styles/typography"
import { LoadingKettlebell } from "../svgs/LoadingKettlebell"
import { SyncingChipTransform } from "../../animations/transitions/SyncingChipTransition"
import { sizes } from "../../styles/sizes"

const ChipContainer = styled.div`
  position: absolute;
  top: 50%;
  left: -100%;
  padding: 6px 10px;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 8px;
  justify-items: center;
  align-items: center;
  background: var(--gradient-purple1-2);
  border-radius: 90px;
  width: fit-content;
  box-shadow: 0 0 0 6px hsla(240, 0%, 0%, 0.4);
  transform: translate(-94%, -50%);
  ${sizes.aboveMobile} {
    transform: translate(-90%, -50%);
  }
  ${sizes.aboveTablet} {
    transform: translate(-80%, -50%);
  }
`

const KettlebellLoader = styled(LoadingKettlebell)`
  width: 20px;
`

export const SyncingChip = () => {
  const isFetching = useIsFetching()

  const styles = {
    "--loader-track-color": "hsla(240, 100%, 100%, 0.2)",
    "--label-color": "var(--color-white-1)",
  } as React.CSSProperties

  return (
    <SyncingChipTransform isSyncing={isFetching > 0}>
      <ChipContainer style={styles}>
        <KettlebellLoader
          loaderType="start_training_plan"
          gradientId="globalSyncingIcon"
        />
        <BoldLabel>Syncing...</BoldLabel>
      </ChipContainer>
    </SyncingChipTransform>
  )
}
